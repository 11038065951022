<template>
  <section class="content-section">
    <div class="inner-container">
      <div class="section-head">
        <h2 class="section-title">Записи игр</h2>
      </div>
      <div class="sorting-panel sorting-panel__with-icons sorting-panel__with-counter">
        <ul class="icon-tabs-list">
          <!--у элементов есть класс active, для подчеркивания-->
          <li class="all icon-tabs-list-item" @click="getAllRecords" :class="{active: activeFilter === 0}">Все</li>
          <li v-for="(game, i) in GET_SPONSOR_GAMES" :key="i" @click="filterByGame(game, i)" :class="{active: activeFilter === i + 1}" class="icon-tabs-list-item">
            <img :src="game.logo" alt="#" >
          </li>
        </ul>
        <div class="sorting-controls__wrapper">
          <div class="sorting-controls">
            <!--"ascending" класс svg для переключения состояния-->
            <svg :class="[{ascending: this.sortOrder.type === 'ascending'}, 'sort-icon']"
                 @click="this.changeSortOrder"
                 width="24" height="24" viewBox="0 0 24 24" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M5 8H19" stroke="#B0B0B0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M8 12.5H16" stroke="#B0B0B0" stroke-width="1.5" stroke-linecap="round"
                    stroke-linejoin="round"/>
              <path d="M10 17H14" stroke="#B0B0B0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div class="sgl-multiselect">
              сортировать по:
              <multiselect
                  v-model="sorting.value"
                  :options="sorting.options"
                  track-by="name"
                  label="name"
                  :searchable="false"
                  :close-on-select="true"
                  :show-labels="false"
                  :preselect-first="true"
                  :allow-empty="false"
                  open-direction="bottom"
                  @select="sortingBy"
              >
                <template slot="singleLabel" slot-scope="{option}">
                  {{ option.name }}
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 6L8 10L12 6" stroke="#B0B0B0" stroke-linejoin="round"/>
                  </svg>
                </template>
              </multiselect>
            </div>
          </div>
          <div class="teams-count">
            кол-во: <span>{{GET_RECORDS_META.page.total}}</span>
          </div>
        </div>
      </div>
      <div class="tabs-container">
        <div class="single-tab single-tab__videos-grid"  v-infinite-scroll="getMoreRecords" infinite-scroll-disabled="isLoaded" infinite-scroll-distance="10" infinite-scroll-throttle-delay="1000">
          <template v-if="isRecordsListShowed">
            <div v-for="(record, i) in GAMES_RECORDINGS" :key="i" class="video-cell">
              <div class="video-cell__media-wrapper">
                <iframe width="100%" height="100%" :src="`https://www.youtube.com/embed${record.attributes.record_path}`" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </div>
              <div class="video-cell__desc">
                <div class="logo">
                  <img class="event-banner--bg" :src="SPONSOR.logo" alt="#">
                </div>
                <div class="text">
                  <div class="title">{{record.attributes.title}}</div>
                  <div class="name">{{ SPONSOR.attributes.name }}</div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <h2 v-if="!GAMES_RECORDINGS.length" class="section-title">По данной дисциплине записи отсутствуют :(</h2>
      </div>
      <div class="spinner-container" v-if="isLoaded">
        <img :src="require('@/img/ico_spinner.png')" alt="#">
      </div>
    </div>
  </section>
</template>

<script>
import Multiselect from "vue-multiselect";
import {mapActions, mapGetters} from 'vuex';

export default {
  name: "recordings-list",
  components: {Multiselect},
  props: {
    records: Array
  },
  data() {
    return {
      sortOrder: {type: 'descending', value: '-'},
      selectedSortOption: '',
      sorting: {
        value: {value: 'created_at', name: 'дате'},
        options: [
          {value: 'created_at', name: 'дате'}
        ]
      },
      page_number: 1,
      page_size: 6,
      activeFilter: 0,
      isLoaded: false,
      stopLoad: false,
      filtering: false,
      filterType: '',
      isSorting: true,
      sortingType: '&sort=-created_at',
      isRecordsListShowed: true,
    };
  },
  computed: {
    ...mapGetters([
      'GAMES_RECORDINGS',
      'GET_RECORDS_META',
      'IS_RECORDS_IN_PROGRESS',
      'GAMES',
      'GET_SPONSOR_GAMES',
      'SPONSOR'
    ]),
    dataToGetRecords() {
      return {
        page_number: this.page_number,
        page_size: this.page_size,
        filtering: this.filtering,
        filter: this.filterType,
        sort: this.sortingType,
        isSort: this.isSorting
      }
    }
  },
  methods: {
    ...mapActions([
      'GET_RECORDINGS'
    ]),
    changeSortOrder() {
      if (this.sortOrder.type === 'ascending') {
        this.sortOrder = {type: 'descending', value: '-'}
      } else {
        this.sortOrder = {type: 'ascending', value: ''}
      }

      this.sortingBy()
    },
    async getMoreRecords() {
      //TODO Возможно переделать на интерсекшин обсервер, чтобы было своё
      if (this.GET_RECORDS_META.page['current-page'] >= this.GET_RECORDS_META.page['last-page']) return

      this.isLoaded = true
      await this.GET_RECORDINGS(this.dataToGetRecords)

      this.page_number++
      this.isLoaded = false
    },
    filterByGame(game, index) {
      this.activeFilter = index + 1
      this.isRecordsListShowed = false

      this.filtering = true
      this.filterType = `&filter[game_id]=${game.id}`
      this.page_number = 1

      this.GET_RECORDINGS(this.dataToGetRecords).then(() => this.isRecordsListShowed = true)
      this.page_number++
    },
    sortingBy() {
      this.isRecordsListShowed = false

      this.isSorting = true
      this.sortingType = `&sort=${this.sortOrder.value}${this.sorting.value.value}`
      this.page_number = 1

      this.GET_RECORDINGS(this.dataToGetRecords).then(() => this.isRecordsListShowed = true)
      this.page_number++
    },
    dropDataToLoadRecords() {
      this.activeFilter = 0
      this.page_number = 1
      this.filtering = false
      this.filterType = ''
    },
    getAllRecords() {
      this.isRecordsListShowed = false
      this.dropDataToLoadRecords()

      this.GET_RECORDINGS(this.dataToGetRecords).then(() => this.isRecordsListShowed = true)
      this.page_number++
    }
  },
  mounted() {
    this.getAllRecords()
  }
}
</script>

<style scoped>

</style>
