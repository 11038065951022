<template>
  <div class="sgl-videos">
    <section class="content-section hero-banner-section">
      <div class="inner-container">
        <div class="event-banner">
          <img class="event-banner--bg" :src="require('@/img/event-hero.png')" alt="#">
          <div class="event-banner--content">
            <div class="event-banner--title">Видео</div>
          </div>
        </div>
      </div>
    </section>

    <active-stream />
    <recordings-list />

    <scroll-top/>
  </div>
</template>

<script>
import ScrollTop from "@/components/UIElements/scrollTop";
import Multiselect from "vue-multiselect";
import {mapActions, mapGetters} from 'vuex';
import ActiveStream from "@/components/streams/activeStream/active-stream";
import RecordingsList from "@/components/streams/recordings/recordingsList";

export default {
  name: "sglVideos",
  components: {RecordingsList, ActiveStream, ScrollTop},
}
</script>

<style scoped>

</style>
